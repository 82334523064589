<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {

    };
  },
};
</script>

<style>
@import "./css/main.css";

@font-face {
  font-family: "Serotiva Bold";
  src: url("./assets/fonts/Serotiva-Bold-iF6613bf2adecc2.otf");
  font-display: swap;
}

@font-face {
  font-family: "Serotiva Semi Bold";
  src: url("./assets/fonts/Serotiva-SemiBold-iF6613bf2ae333a.otf");
  font-display: swap;
}

@font-face {
  font-family: "Serotiva Normal";
  src: url("./assets/fonts/Serotiva-Regular-iF6613bf2b050b2.otf");
  font-display: swap;
}

@font-face {
  font-family: "Serotiva Medium";
  src: url("./assets/fonts/Serotiva-Medium-iF6613bf2aefd03.otf");
  font-display: swap;
}

@font-face {
  font-family: "Serotiva Extra Bold";
  src: url("./assets/fonts/Serotiva-ExtraBold-iF6613bf2ad61ab.otf");
  font-display: swap;
}

@font-face {
  font-family: "Amstir Pixel";
  src: url("./assets/fonts/amstirpixel-regular.otf");
  font-display: swap;
}

html {
  scroll-behavior: smooth;

  scroll-padding-top: 74px;
}

body {
  margin: 0;
}

@media (min-width: 600px) {
  html {
    scroll-padding-top: 47px;
  }
}

@media (min-width: 1240px) {
  html {
    scroll-padding-top: 85px;
  }
}

@media (min-width: 1441px) {
  html {
    scroll-padding-top: 85px;
  }
}
</style>
