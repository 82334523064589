import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from 'vue-dayjs';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMeta from 'vue-meta';

import {url} from './global/index'; 

Vue.use(dayjs)
Vue.use(VueMeta)
Vue.use(VueAxios, axios)

axios.defaults.baseURL =url;

Vue.config.productionTip = false

new Vue({
  created() {
    
  },
  router,
  store, 
  render: h => h(App),
}).$mount('#app')
