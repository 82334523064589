import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)




const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
  },
 
  {
    path: '*',
    component: () => import('../views/Error404'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  next()
});

export default router